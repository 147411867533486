import {Link } from "react-router-dom";


import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function Login ()
{
    return (
            <> 
            
                <Head />

                <div className='floatingCryptocoins'>
                    <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe>
                    </div>

                    <section>
                        <div className='userLoginContainer'>

                                <div className='userLoginContainerSub'>

                                <div className='userLoginContainerSubCard1'>
                                    {/* <img src="bg/login.svg" alt="Login Image" width={400} height={400} /> */}
                                </div>

                                <div className='userLoginContainerSubCard2'>

                                        <div className='userLoginContainerSubCard2Form'>


                                                     <div className="row justify-content-center">
                                                        <div className="col-lg-12">
                                                        <form action="#" method="post" role="form" className="php ">
                                                        
                                                            <h3>SIGN IN</h3>
                                                            
                                                            <div className="form-group mt-3">
                                                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Username"
                                                                    required />
                                                            </div>
                                                            <br />

                                                            <div className="form-group mt-3">
                                                                <input type="password" className="form-control" name="subject" id="subject" placeholder="Enter Password"
                                                                    required />
                                                            </div>

                                                            <div className='loginFormSubContainer'>
                                                            
                                                                    <div className='noAcct'>

                                                                        <Link to='/register'>
                                                                            Don't have an account? Sign Up Now.
                                                                        </Link>
                                                                    </div>


                                                                <div className='forgetPwd'>
                                                                <Link to='/forgotpwd'>
                                                                        Forgot Password.
                                                                    </Link>
                                                                </div>


                                                                <p className="clearx"></p>
                                                            </div>

                                                           
                                                           
                                                           
                                            
                                                            <div className="mt-3">
                                                                <button className="cmn-btn w-100 buttForm" type="submit">SIGN IN</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                        </div>
                                </div>





                                <p className="clearx"></p>
                                </div>

                           
                        </div>

                    </section>














                
                <Foot />
            
            
            
            
             </>

    );


}