
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";



export default function About ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSection'>

                    <div className='floatingCryptocoins'>
                    {/* <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe> */}
                    </div>

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            <div className='mainquickLinkStyle' style={{
                                width: '100%',
                                height: 'auto',
                                // backgroundColor:'blue',
                                
                    
                            }}>

                                <h3 style={{
                                    color: "white",
                                    textAlign: "center",
                                    padding:3,
                                }}> Quick Link</h3>

                               

<Link to="/ledligh" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>LED Lighting</p>
                                </div>
                                </Link>


                                <Link to="/energ" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>Energy</p>
                                </div>
                                </Link>

                                <Link to="/itserv" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>IT Services</p>
                                </div>
                                </Link>
                                
                                <Link to="/freeana" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>Free Analysis</p>
                                </div>
                                </Link>








                            </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2> Free Analysis
                                </h2>


                                <p>
                                Thank you for taking the time to check us out. We would like to provide you with a free analysis to show you just how much you can save by switching to LED lighting in your facility. We will examine your current lighting and provide you with a complete project analysis, including but not limited to, understanding the type and number of your current fixtures (fluorescent, Metal Halide, interior and exterior, etc). We will recommend new lighting, and provide you with an exact amount of electricity you will save which translates into direct monetary savings. You will save between 50% to 75% of your monthly lighting costs. We will also work with your local utility provider to determine what rebates you may be entitled to. At the completion of our analysis, you will know your Return on Investment (ROI) period of time. Should you choose to hire us we will provide all the paperwork to get you the largest rebate possible. As part of our analysis we will show you how to complete the project with no upfront out of pocket costs.



                                </p>

                                <p>
                                Whether you hire us or not, once we complete your analysis you will receive a free gift. This gift will show you the power of utilizing LED technology.


                                </p>
                                <p>
                                To get started contact us by completing the form below so we can contact you directly or call us at (866) 337-9448 x710.



                                </p>




                                {/* <h5>Additional Services
                                </h5>
                                <p>
                                eFederal provides turn key solutions to commercial clients and government agencies. Although this is our primary job, one that we do efficiently and cost effectively, if you have in house personnel who can perform the work we are happy to supply the hardware, fixtures and tubes a great pricing.


                                </p>
                                <p>
                                If you are interested in Energy Optimization for your entire facility let us provide you with a free audit Energy Optimization. If you need IT services or hardware we have a group that specializes in the IT environment IT Services.


                                </p>
                                <p>

                                </p> */}

                                
                           

                                {/* <div className='companyCert'>
                                    <p>View Company Certificate</p>
                                </div> */}

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>




                   


                    















                <Foot />
            
            
            
            
             </>

    );


}