
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function Faq ()
{
    return (
            <> 
            
                <Head />


                <div className='floatingCryptocoins'>
                    {/* <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe> */}
                    </div>




        {/* faq section */}
        <section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                   
                                   

                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q1?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div className="accordion-body">
                                        A1
                                        </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q2?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A2
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q3?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                            A3
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                            Q4?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                           A4
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q5?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                       A5
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q6?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo4" class="accordion-collapse collapse" aria-labelledby="headingTwo4" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A6
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                       Q7?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A7
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}


                        </div>


                    </div>


                </div>   
            </section>
















                
                <Foot />
            
            
            
            
             </>

    );


}