import { BrowserRouter, Routes, Route } from 'react-router-dom';


// pages
import Home from './HomePages/Home';
import About from './HomePages/About';
import Plans from './HomePages/Plans';
import Contact from './HomePages/Contact';
import Faq from './HomePages/Faq';
import Login from './HomePages/Login';
import Register from './HomePages/Register';
import ForgotPwd from './HomePages/ForgotPwd';

// import LedLighting from './HomePages/LedLighting';
import Energy from './HomePages/Energy';
import ItServices from './HomePages/ItServices';
import FreeAnalysis from './HomePages/FreeAnalysis';
import LedLights from './HomePages/LedLights';
import PageNoteFound from './HomePages/PageNoteFound';

function App() {
  return (
        <>

<BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route  path='/about' element={<About />} />
        <Route  path='/plans' element={<Plans />} />
        <Route  path='/contact' element={<Contact />} />
        <Route  path='/faq' element={<Faq />} />
        <Route  path='/login' element={<Login />} />
        <Route  path='/register' element={<Register />} />
        <Route  path='/forgotpwd' element={<ForgotPwd />} />

        <Route  path='/ledligh' element={<LedLights />} />
        <Route  path='/energ' element={<Energy />} />
        <Route  path='/itserv' element={<ItServices />} />
        <Route  path='/freeana' element={<FreeAnalysis />} />
        



        <Route  path='*' element={<PageNoteFound />} />

      </Routes>
    </BrowserRouter>
          
        
        
        
        
        
        
        </>



  );
}

export default App;
