import { useEffect } from "react";
import $ from 'jquery';
import {Link } from "react-router-dom";


export default function Head () {



    useEffect(() => {
        
        window.scrollTo(0, 0);

    },[]);



 

   const  showMobileNav = () =>{

        $("#exampleModal").modal('show');

        alert("y");
       
    }


    return (    
            <>
                <header>
                    <div className="headerContainer">

                        <div className="headerContainer_Sub">


                                <div className="logoSection">
                                    <Link to='/'>
                                        <h2>eFederal  <span style={{color: "#eaecef"}}>Systems</span></h2>
                                    </Link>
                                    
                                    
                                </div>


                                    <div className="navBarContainerx" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    {/* <div className="navBarContainerx" onClick={showMobileNav}> */}
                                        <span className="material-symbols-outlined">
                                            menu
                                        </span>  
                                    </div>




                    <div className="divHoldingMobileNav">

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content modal-contentHomeNav">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Menu</h1>
                                    <button type="button" className="btn-close closeNavHoldButton" data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor: "white"}}></button>
                                </div>
                                <div className="modal-body">

                                {/* <span class="material-symbols-outlined">
                                home
                                </span> */}

                                        {/* nav content */}
                                    <div className="navContent">
                                        <div className="navContentSub">


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/" >
                                                <center>
                                                <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                home
                                                </span>
                                                <br />
                                                 <p data-bs-dismiss="modal"> Home </p> 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/about">
                                                 <center>
                                                 <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                   groups
                                                 </span>
                                                <br />
                                                 About 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                                <Link to="/plans">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                   payments
                                                 </span>
                                                <br />
                                                Solutions 
                                                </center>
                                                </Link> 
                                            </div>

                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/contact">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                 support_agent
                                                 </span>
                                                <br />
                                                 Support 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/faq">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                 quiz
                                                 </span>
                                                <br />
                                                 Faq 
                                                </center>
                                                </Link> 
                                            </div>


                                            <div className="navContentSubContent" data-bs-dismiss="modal">
                                            <Link to="/energ">
                                                <center>
                                                 <span class="material-symbols-outlined" style={{color: "#F0B90B", marginTop: 10,}}>
                                                 logout
                                                 </span>
                                                <br />
                                                Energy 
                                                </center>
                                                </Link> 
                                            </div>


                                            <p className="clearx"></p>
                                        </div>


                                        <Link to='/register'>
                                            <div className="navReg" data-bs-dismiss="modal">
                                                <p>Free Analysis</p>
                                            </div>
                                        </Link>
                                       


                                    </div>







                                    
                                    
                                </div>
                               
                                </div>
                            </div>
                        </div>

                    </div>
               




                                <div className="navSection">
                                    <div className="navItems">
                                        <ul>
                                            <li><Link to="/"> Home </Link> </li>
                                            <li><Link to="/about"> About </Link> </li>
                                            <li><Link to="/plans"> Solutions </Link> </li>
                                            {/* <li><Link to="/blog"> Blog  </Link> </li> */}
                                            <li><Link to="/contact"> Contact </Link> </li>
                                            <li><Link to="/faq"> FAQ </Link> </li>
                                            {/* <li><Link to="/services"> Services </Link> </li> */}
                                            {/* <li><Link to="/team"> Team </Link> </li> */}
                                            {/* <li><Link to="/login"> Resources </Link> </li> */}
                                            <li><Link to="/freeana"> Free Analysis </Link> </li>
                                            {/* <li><span className="googleTrans"> Google Translate </span></li> */}
                                        </ul>
                                    </div>   

                                    <div className="navMobile">
                                    
                                    </div> 
                                    
                                </div>
                                

                               

                                <p className="clearx"></p>





                               
                        </div>


                        <p className="clearx"></p>


                    </div>
                </header>

            

                <div className="navbarx">
  <a href="#home" className="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
</div>












{/* modal for the header nav */}

















            </>
    );

}