
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function About ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSection'>

                    <div className='floatingCryptocoins'>
                    {/* <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe> */}
                    </div>

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img1'> </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2>Welcome To eFederal Systems </h2>


                                <p>
                                We specialize in providing innovative, cutting-edge LED lighting solutions for various industries and applications, transforming spaces and revolutionizing the way you live and work. Our online catalog offers a vast selection of energy-efficient LED products, including indoor and outdoor lighting solutions, designed to meet the unique needs of our clients.

                                </p>

                                <h5>WHY YOU CHOOSE US:</h5>
                                <p>
                                 <b>Expertise in LED lighting and alternative Energy:</b> Trust our team of experts to guide you through the latest technologies and innovations.


                                </p>
                                <p>
                                 <b>Access to a comprehensive online catalog:</b> Explore our vast selection of energy-efficient LED products and discover the perfect solution for your needs.

                                </p>
                                <p>
                                    <b>⁠Commitment to sustainability and energy efficiency:</b>  Join us in our mission to create a more sustainable future, one energy-efficient solution at a time.


                                </p>
                                <p>
                                    <b>Personalized service and support: </b>  Experience the exceptional service and support that sets us apart, and discover why our clients choose us time and time again.

                                </p>
                                {/* <h5>Trust:</h5> */}
                                <p style={{ color: "#F0B90B"}}>
                                <i>
                                We're passionate about promoting sustainable energy solutions and investing in alternative energy projects that make a real difference. Our expertise in this field enables us to provide valuable insights, guidance, and support on renewable energy initiatives, helping you navigate the path to a greener, more sustainable future.
                                </i>
                                </p>

                                <p>
                                eFederal Systems is a rapidly growing certified Service Disabled Veteran Owned Small Business (SDVOSB) with offices in Virginia, Maryland and South Carolina. We focus on providing a full range of services, including IT consulting, Hardware/Software sales, and Energy Saving Technology to not only the Federal government, but to state and local agencies as well and commercial businesses.


                                </p>
                           

                                {/* <div className='companyCert'>
                                    <p>View Company Certificate</p>
                                </div> */}

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>




                    <section>

                        <div  className='aboutSection1'>
                            <div className='aboutSection1Sub'>
                                <div className='aboutSection1SubCard'>
                                   
                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg1'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>LIGHTING</h2>
                                            <p>
                                            LED Lighting is the way to dramatically reduce the amount of money spent on electricity month over month, year after year!


                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                        
                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg11'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>GROW LIGHTS
                                            </h2>
                                            <p>
                                            
                                            If you are looking for Cannabis grow lights, we supply the highest quality lights. Single to 10 bar fixtures


                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg111'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>IT SERVICES</h2>
                                            <p>
                                            
                                            eFederal also provides IT services including Enterprise Architecture, Development and Implementation


                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                            </div>

                        </div>



                    </section>




                    <section>
                <div className='whyChooseUsSection'>
                    <div className='whyChooseUsSectionContainer'>

                            <div className='whyChooseUsSectionContainerHeading'>
                                <h2>Reasons Why We Are The Best.</h2>
                                <p>
                                Our goal is to provide our investors with a reliable source of income, while minimizing risks and offering high-quality services.

                                </p>
                            </div>


                            <div className='whyChooseUsSectionContainerDetails'>

                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Legal Company</h2>
                                        <p>
                                        Our company conducts absolutely legal activities in the legal field. We are certified to operate, we are legal and safe.


                                        </p>
                                    </div>
                                    
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Expertise in LED Lighting
                                        </h2>
                                        <p>
                                        Trust our team of experts to guide you through the latest technologies and innovations.


                                        </p>
                                    </div>
                                    

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>

                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Verified Security</h2>
                                        <p>
                                        We are trusted by a huge number of people. We are working hard constantly to improve the level of our security system and minimize possible risks.

                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Commitment to sustainability</h2>
                                        <p>
                                        Join us in our mission to create a more sustainable future, one energy-efficient solution at a time.


                                        </p>
                                    </div>

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Referral Program</h2>
                                        <p>
                                        We are offering a certain level of referral income through our referral program, you can increase your income by simply referring a few people.
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>24/7 Support</h2>
                                        <p>
                                        We provide 24/7 customer support through e-mail and live chat. Our support representatives are periodically available to elucidate any difficulties.
                                        </p>
                                    </div>

                                </div>

                            </div>



                    </div>


                </div>

        </section>



        {/* our team */}


        <section>
            <div className='ourTeamContainer'>

                <div className='ourTeamContainerSub'>
                    <h2>Our Teams</h2>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                    <img src="test/pro1.svg" alt="ceo" width={80} height={80} />
                                </div>
                                <p>Founder & CEO</p>
                            </center>

                        </div>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                    <img src="test/pro1.svg" alt="ceo" width={80} height={80} />
                                </div>
                                <p>Investment Analyst</p>
                            </center>

                        </div>
                        <div className='ourTeamContainerSubCard'>
                            
                            <center>
                                <div className='founderImg'>
                                    <img src="test/pro1.svg" alt="ceo" width={80} height={80} />
                                </div>
                                <p>Manager</p>
                            </center>


                        </div>
                        <div className='ourTeamContainerSubCard'>

                            
                            <center>
                                <div className='founderImg'>
                                    <img src="test/pro1.svg" alt="ceo" width={80} height={80} />
                                </div>
                                <p>Marketing Officer</p>
                            </center>


                        </div>



                    <p className="clearx"></p>
                </div>


            </div>


        </section>















                <Foot />
            
            
            
            
             </>

    );


}