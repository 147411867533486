
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

export default function Home () {
    return (
        <>
        
            <Head />
        

        {/* home section */}



{/* section 1 */}
        <section>

            <div className='showCaseContainer'>
                <div className='showCaseContainer_Sub'>

                    <div className='showCaseContainer_Sub_1'>

                        <div className='showCaseContainer_Sub_1_Container'>
                            <h3 style={{ textTransform: 'capitalize'}}>
                            dedicated to providing our clients with energy savings technology...

                            </h3>
                            <p>
                            So you can reduce your monthly power costs.


                            </p>

                            <div className='showCaseButtonContainer'>

                                <div className='showCaseButtonHideMobile'>

                                    <div className='showCaseButton_1'>
                                        <a href="/register">Get Started</a>
                                    </div>

                                    {/* <div className='showCaseButton_2'>
                                        <a href="/login">Login</a>
                                    </div> */}


                                    
                                    <p className="clearx"></p>
                                </div>

                               

                                <p className='showCaseParaBelow' style={{color: "#F0B90B"}}>
                                    Trusted By More Than 50,000+ Users
                                </p>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </section>
        

        {/* section 2 */}
        <section>
                <div className='aboutSection'>

                    {/* <div className='aboutSection_sub1'>
                    <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe>
                    </div> */}

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img1'> </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2>About Us</h2>


                                <p>
                                Empowering a Brighter Tomorrow
LED Lighting Solutions & Alternative Energy Experts.


                                </p>

                                <p>
                                We specialize in providing innovative, cutting-edge LED lighting solutions for various industries and applications, transforming spaces and revolutionizing the way you live and work. Our online catalog offers a vast selection of energy-efficient LED products, including indoor and outdoor lighting solutions, designed to meet the unique needs of our clients.

                                </p>

                                <h5>WHAT WE DO:</h5>
                                <p>
                                 <b>LED Lighting Solutions:</b> Browse our online catalog for a vast selection of LED products, from commercial to residential applications, and discover the perfect solution for your unique needs.

                                </p>
                                <p>
                                 <b>Alternative Energy Projects:</b> Learn about our involvement in sustainable energy initiatives, such as solar, wind, and hydro power, and how we can help you invest in a brighter, more sustainable future.

                                </p>
                                <p>
                                    <b>⁠Energy Efficiency Consulting:</b>  Our team of experts provides personalized advice on reducing energy consumption and costs through LED lighting and alternative energy solutions.

                                </p>
                                <p>
                                    <b>Sales: </b>  We offer competitive pricing and expert guidance to help you find the perfect LED lighting and alternative energy solutions for your needs.

                                </p>
                                {/* <h5>Trust:</h5> */}
                                <p style={{ color: "#F0B90B"}}>
                                <i>
                                We're passionate about promoting sustainable energy solutions and investing in alternative energy projects that make a real difference. Our expertise in this field enables us to provide valuable insights, guidance, and support on renewable energy initiatives, helping you navigate the path to a greener, more sustainable future.
                                </i>
                                </p>
                                {/* <h5>Value:</h5>
                                <p>
                                We agreed to work to keep the cost of investing as low as possible and to offer our services in a spirit of partnership, helping our customers to be profitable investors, not just profitable customers. After all, if you do well, we do well.
                                </p> */}

                                {/* <
                                div> */}

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>
        






        {/* section 3 */}

        <section>
                <div className='whyChooseUsSection'>
                    <div className='whyChooseUsSectionContainer'>

                            <div className='whyChooseUsSectionContainerHeading'>
                                <h2>Reasons Why We Are The Best.</h2>
                                <p>
                                
                                We specialize in providing innovative, cutting-edge LED lighting solutions for various industries and applications, transforming spaces and revolutionizing the way you live and work. 


                                </p>
                            </div>


                            <div className='whyChooseUsSectionContainerDetails'>

                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Legal Company</h2>
                                        <p>
                                        Our company conducts absolutely legal activities in the legal field. We are certified to operate, we are legal and safe.

                                        </p>
                                    </div>
                                    
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Expertise in LED Lighting</h2>
                                        <p>
                                        Trust our team of experts to guide you through the latest technologies and innovations.


                                        </p>
                                    </div>
                                    

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>

                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Verified Security</h2>
                                        <p>
                                        We are trusted by a huge number of people. We are working hard constantly to improve the level of our security system and minimize possible risks.

                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Commitment to sustainability</h2>
                                        <p>
                                        Join us in our mission to create a more sustainable future, one energy-efficient solution at a time.

                                        </p>
                                    </div>

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>Referral Program</h2>
                                        <p>
                                        We are offering a certain level of referral income through our referral program, you can increase your income by simply referring a few people.
                                        </p>
                                    </div>
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>24/7 Support</h2>
                                        <p>
                                        We provide 24/7 customer support through e-mail and live chat. Our support representatives are periodically available to elucidate any difficulties.
                                        </p>
                                    </div>

                                </div>

                                <p className="clearx"></p>
                            </div>



                    </div>


                </div>

        </section>
        






        {/* section 4 */}
        <section>
            <div className='sectionFourContainer'>
                <div className='sectionFourContainerSub'>

                    <div className='sectionFourContainerSubCard1'></div>

                    <div className='sectionFourContainerSubCard2'>
                        <div className='sectionFourContainerSubCard2Details'>

                            <h3>
                            We always put the customer first and have proven experience...


                            </h3>


                            <div className='stepsContainer'>

                                <h5>LIGHTING: </h5>
                                <p>
                                LED Lighting is the way to dramatically reduce the amount of money spent on electricity month over month, year after year!


                                </p>

                                <h5>GROW LIGHTS: </h5>
                                <p>
                                If you are looking for Cannabis grow lights, we supply the highest quality lights. Single to 10 bar fixtures


                                </p>

                                <h5>IT SERVICES: </h5>
                                <p>
                                eFederal also provides IT services including Enterprise Architecture, Development and Implementation


                                </p>


                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>

        </section>
        



            {/* faq section */}
            <section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q1?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div className="accordion-body">
                                        A1
                                        </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q2?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A2
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q3?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                            A3
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                            Q4?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                           A4
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q5?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                       A5
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo4" aria-expanded="false" aria-controls="collapseTwo4" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                        Q6?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo4" class="accordion-collapse collapse" aria-labelledby="headingTwo4" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A6
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#1E2026", color: "#F0B90B"}}>
                                       Q7?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#1E2026", color: "#eaecef"}}>
                                        <div class="accordion-body">
                                        A7
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>




                {/* referral */}
                <section>
                        <div className='referralContainer'>

                            <div className='referralContainerSub'>
                                <h2>eFederal Systems</h2>
                                <p>
                                
                                We are dedicated to providing our clients with energy savings technology, so that they can reduce their monthly power costs. Additionally, you can save even more by letting us help you reduce the electricity consumed by the motors and A/C in your facility.



                                </p>

                                {/* <center>
                                <a href="">Sign Up</a>
                                </center> */}
                                
                            </div>

                        </div>


                </section>





            {/* debit card */}
                <section>

                    <div className='cryptoDebitCardContainer'>
                        <div className='cryptoDebitCardContainerSub'>

                            <div className='cryptoDebitCardContainerSubCard1'></div>

                            <div className='cryptoDebitCardContainerSubCard2'>
                                    <h2>A Service And Product Company</h2>

                                    <p>
                                   
                                    eFederal Systems is a rapidly growing certified Service Disabled Veteran Owned Small Business (SDVOSB) with offices in Virginia, Maryland and South Carolina. We focus on providing a full range of services, including IT consulting, Hardware/Software sales, and Energy Saving Technology to not only the Federal government, but to state and local agencies as well and commercial businesses.



                                    </p>

                                    
                                    <a href="">Get Started</a>
                                

                            </div>

                            <p className="clearx"></p>
                        </div>

                    </div>

                </section>





            {/* testionial */}
            <section>
                    <div className='testimonailContainer'>
                        <div className='testimonailContainerSub'>
                            <h2>TESTIMONIES</h2>
                            <p>
                            We are doing really good at this market and here are the words we loved to get from a few of our investors.

                            </p>


                                <div className='testimonailCardContainer'>

                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                                t1
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                               t2
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                               t3
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                               t4
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                                t5
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                <center>
                                                    <div className='testImage'>
                                                        <img src="test/pro1.svg" alt="" width={50} height={50} />
                                                    </div>
                                                </center>

                                                <p className='testimonailCardDetails1'>
                                                t6
                                                </p>

                                                <p className='testimonailCardDetails2'>
                                                    Mary James, USA.
                                                </p>  
                                        </div>
                                    </div>

                                    <p className="clearx"></p>
                                </div>


                        </div>
                    </div>
            </section>









                {/* footer */}

                <Foot  />








        
        
        
        
        
        
        
        </>
    );
}