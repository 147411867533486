
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";



export default function About ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSection'>

                    <div className='floatingCryptocoins'>
                    {/* <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe> */}
                    </div>

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            <div className='mainquickLinkStyle' style={{
                                width: '100%',
                                height: 'auto',
                                // backgroundColor:'blue',
                                
                    
                            }}>

                                <h3 style={{
                                    color: "white",
                                    textAlign: "center",
                                    padding:3,
                                }}> Quick Link</h3>

                               

<Link to="/ledligh" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>LED Lighting</p>
                                </div>
                                </Link>


                                <Link to="/energ" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>Energy</p>
                                </div>
                                </Link>

                                <Link to="/itserv" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>IT Services</p>
                                </div>
                                </Link>
                                
                                <Link to="/freeana" >
                                <div className='quickLinkStyle'>
                                    <p style={{color: "white"}}>Free Analysis</p>
                                </div>
                                </Link>








                            </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                                <h2> Optimization </h2>


                                <p>
                                To learn more about our energy optimization program, contact us at Info@eFederalSystems.com



                                </p>

                                {/* <p>
                                Our team of professionals has worked as part of the National Security Staff, developed interactive web sites and created a proprietary real-time negotiations system in support of state and federal agencies.


                                </p>
                                <p>
                                As energy costs rise we have also turned our attention to developing and implementing ways to reduce the consumption of electricity. LED lighting can save a facility as much as 65% in monthly energy costs and completely eliminate maintenance costs. For more information click on the LED Lighting link below or contact us at Info@eFederalSystems.com.


                                </p> */}




                                <h5>Additional Services
                                </h5>
                                <p>
                                eFederal provides turn key solutions to commercial clients and government agencies. Although this is our primary job, one that we do efficiently and cost effectively, if you have in house personnel who can perform the work we are happy to supply the hardware, fixtures and tubes a great pricing.



                                </p>
                                <p>
                                If you are interested in LED Lighting for your facility let us provide you with a free audit LED Lighting. If you need IT services or hardware we have a group that specializes in the IT environment IT Services.



                                </p>
                                <p>

                                </p>

                                
                           

                                {/* <div className='companyCert'>
                                    <p>View Company Certificate</p>
                                </div> */}

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>




                   


                    















                <Foot />
            
            
            
            
             </>

    );


}