

import {Link } from "react-router-dom";




export default function Foot ()
{

    return(
            <>
            
            
            <footer>
                    <div className='footerContainer'>
                        <div className='footerContainerSub'>

                            <div className='footerContainerSubCard'>
                                    <h3>eFederal Systems</h3>
                                    <p>
                                    We are dedicated to providing our clients with energy savings technology, so that they can reduce their monthly power costs. Additionally, you can save even more by letting us help you reduce the electricity consumed by the motors and A/C in your facility.


                                    </p>
                            </div>


                            <div className='footerContainerSubCard'>
                                <h4>Links</h4>
                                <ul>
                                    <li><Link to='/'>Home </Link></li>
                                    <li><Link to='/about'>About  </Link></li>
                                    <li><Link to='/plans'> Solutions </Link></li>
                                    <li><Link to='/terms'> Contact </Link> </li>
                                </ul>
                            </div>
                            <div className='footerContainerSubCard'>
                                <h3>Contact</h3>
                                    <p>
                                        Office:<br />  Suite 250, 8216 Little Sydney's way, Charleston, SC 29406
                                    </p>

                                    <p>
                                        Email: <br />
                                       
                                        info@eFederalSystems.com
                                    </p>

                            </div>

                            <p className="clearx"></p>

                            <p className='footLastText'>
                                © 2025 eFederal Systems. All Rights Reserved.

                            </p>
                        </div>

                    </div>
                </footer>









            
            
            
            
            
            
            </>

    );




}