import {Link } from "react-router-dom";


import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function About ()
{
    return (
            <> 
            
                <Head />

                <div className='floatingCryptocoins'>
                    <iframe src="https://widget.coinlib.io/widget?type=horizontal_v2&amp;theme=dark&amp;pref_coin_id=1505&amp;invert_hover=no" frameborder="0" border="0" width={"100%"} height={36} scrolling="auto" marginwidth="0" marginheight="0"></iframe>
                    </div>

                    <section>

                        <div className='registerContainer'>



                        <div className='registeruserLoginContainer'>

<div className='registeruserLoginContainerSub'>

<div className='registeruserLoginContainerSubCard1'>
    {/* <img src="bg/login.svg" alt="Login Image" width={400} height={400} /> */}
</div>

<div className='registeruserLoginContainerSubCard2'>

        <div className='registeruserLoginContainerSubCard2Form'>


                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                        <form action="#" method="post" role="form" className="php ">
                        
                            <h3>SIGN UP</h3>
                            <br />

                                                     <div className="row">
                                                                <div className="col-md-6 form-group">
                                                                    <input type="text" name="name" className="form-control" id="name" placeholder="Enter First Name"
                                                                        required />
                                                                </div>
                                                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                                                    <input type="text" className="form-control" name="email" id="email" placeholder="Enter Last Name"
                                                                        required />
                                                                </div>
                                                            </div>

                            <br />
                            <div className="form-group mt-3">
                                <input type="email" className="form-control" name="subject" id="subject" placeholder="Enter Email"
                                    required />
                            </div>
                            <br />
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Username"
                                    required />
                            </div>
                            <br />
                            <div className="form-group mt-3">
                                <input type="password" className="form-control" name="subject" id="subject" placeholder="Enter Password"
                                    required />
                            </div>
                            <br />
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Retype Password"
                                    required />
                            </div>
                            <br />

                            

                            <div className='loginFormSubContainer'>
                            
                                    <div className='noAcct'>

                                        <Link to='/login'>
                                            Already have an account? Sign In Now.
                                        </Link>
                                    </div>


                                <div className='forgetPwd'>

                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" required id="exampleCheck1" style={{border:"1px solid #F0B90B"}}  />
                                    <span className='iagree'>
                                        <a href=''>I agree to the terms</a>
                                    </span>

                                </div>

                                </div>


                                <p className="clearx"></p>
                            </div>

                           
                           
                           
            
                            <div className="mt-3">
                                <button className="cmn-btn w-100 buttForm" type="submit">SIGN UP</button>
                            </div>
                        </form>
                    </div>
                </div>

        </div>
</div>





<p className="clearx"></p>
</div>


</div>




                        </div>



                    </section>






                <Foot />
            
            
            
            
             </>

    );


}